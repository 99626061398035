<!--
 * @Author: wangwei
 * @Date: 2020-12-28 16:06:28
 * @LastEditTime: 2023-01-17 20:11:04
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/views/member/order/List.vue
-->
<template>
  <div>
    <div class="sld_order_list">
      <MemberTitle :memberTitle="L['我的供应商']"></MemberTitle>
      <div class="container">
        <h3>{{ L["我的供应商"] }}</h3>
        <div class="sld_order_nav">
          <div class="headerSearch">

            <el-form

              ref="ruleFormRef"
              :model="param"
              :inline="true"
              class="demo-form-inline "
            >
              <el-form-item class="inputSearch" label="供应商名称" prop="supplierName">
                <el-input v-model="param.supplierName" width="300px" placeholder="请输入供应商名称"/>
              </el-form-item>
              <!--<el-form-item>-->
              <div class="searchBtn">
                <div class="button" @click="onSubmit">搜索</div>
                <el-button @click="resetForm(ruleFormRef)">重置</el-button>
              </div>
              <!--</el-form-item>-->
            </el-form>
          </div>
          <div></div>
        </div>
        <div class="sld_order_button">
          <div class="addButton" @click="addPlan">邀请供应商</div>
        </div>
        <div class="sld_List">
          <el-table
            :data="tableData.data"
            border
            max-width="250"
            header-cell-style="background:#FAFAFAFF;"
          >
            <el-table-column
              type="index"
              label="序号"
              width="50"
              align="center"
            />
            <el-table-column
              prop="supplierName"
              label="供应商"

              align="center"
            />
            <el-table-column
              prop="status"
              label="供应商状态"

              align="center"
            >
              <template #default="scope">
                <p v-if="scope.row.status>=4&&scope.row.status!==6" style="width:80px;text-align:left;margin:0 auto">

                    <span class="greenCircle"></span>
                    <span>已入驻</span>
                </p>
                <p v-if="scope.row.status<4&&scope.row.status!==6" style="width:80px;text-align:left;margin:0 auto">

                    <span class="redCircle"></span>
                    <span>未入驻</span>
                </p>
                <p v-if="scope.row.status==6" style="width:80px;text-align:left;margin:0 auto">
                    <span class="redCircle" style="background:#ccc;"></span>
                    <span>已淘汰</span>
                </p>
              </template>
            </el-table-column>



            <el-table-column
              prop="inviteDate"
              label="邀请时间"
              align="center"
            />

            <el-table-column  label="操作" align="center">
              <template #default="scope">
                <p
                v-if="scope.row.status>=4&&scope.row.status!==6"
                  class="look"
                  type="primary"
                  size="small"
                  @click="toSupplierHomePage(scope.row.id,scope.row.supplierName)"
                  link
                >
                  进入供应商主页
                </p>
                <p
                v-if="scope.row.status<4||scope.row.status==6"
                  type="primary"
                  size="small"
                  link
                >
                  /
                </p>
              </template>
            </el-table-column>
          </el-table>
          <!--<el-pagination
             v-model:currentPage="param.pageNum"
            :page-size="param.pageSize"
            :page-sizes="[10, 20, 30, 40]"
            :small="small"
            :total="param.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />-->
          <el-pagination
            style="margin-top: 10px"
            :vi-if="param.total != 0"
            v-model:current-page="param.pageNum"
            v-model:page-size="param.pageSize"
            :page-sizes="[10, 20, 30, 40]"
            :small="small"
            :disabled="disabled"
            :background="background"
            layout="total, sizes, prev, pager, next, jumper"
            :total="param.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
          <!--<el-pagination layout="prev, pager, next" :total="1000" />-->
          <!--<el-pagination
          @current-change="handleCurrentChangeCoupon"
          v-model:currentPage="curCouponPage"
            :page-size="couponPageSize"
            layout="prev, pager, next, jumper"
            :total="couponList.data.pagination.total"
            :hide-on-single-page="true">
          </el-pagination>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, getCurrentInstance, ref, onMounted } from "vue";
import {
  ElInput,
  ElMessage,
  ElMessageBox,
  ElTimeline,
  ElTimelineItem,
} from "element-plus";
// import { Search } from '@element-plus/icons-vue'
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { apiUrl } from "../../../utils/config";
import axios from "axios";
import SldCommonEmpty from "../../../components/SldCommonEmpty";
import MemberTitle from "../../../components/MemberTitle";
import download from "downloadjs";

export default {
  name: "order-list",
  components: {
    ElInput,
    ElTimeline,
    ElTimelineItem,
    SldCommonEmpty,
    MemberTitle,
  },
  setup() {
    // const balance = require("../../assets/buy/balance.png");
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const memberInfo = reactive({ data: store.state.memberInfo });
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const current_state = ref(""); //当前列表订单状态
    const evaluate_state = ref(0); //是否是待评价
    const ruleFormRef = ref();
    const orderSn = ref(""); //订单id
    let orderProductObj = reactive({});
    let orderProductList = reactive({ data: [] }); //确认收货展示数据
    const tableData = reactive({ data: [] });
    const param = reactive({
      memberId: store.state.memberInfo.memberId,
      supplierName:"",
      pageNum: 1,
      pageSize: 10,
      total: 0,
    });

    //const pageData = reactive({
    //  pageNum: 1,
    //  pageSize: 10,
    //  total: 0,
    //});
    const onSubmit = () => {
    console.log(param);
     getSupplierList(param);
      console.log("submit!");
    };
    const toSupplierHomePage = (id,name) => {
      console.log(id,name);
      router.push({
        path: "/goods/list",
        query: {
          supplierId: id,
          supplierUserName:name
        },
      });
    };
    const resetForm = (formEl) => {
      if (!formEl) return;
      formEl.resetFields();
      param.pageNum=1
      getSupplierList(param);
      //param.resetFields()
      //param.planCode= "",
      // param. source= "",
      // param. prayName= "",
      // param. startDate= "",
      // param. endDate= "",
      //  param.resource= "",
      //  param.state= "",
      //getOrderList()
    };

    const getSupplierList = (params) => {
      proxy.$get("api/shopp/supplier/querySupplier", params).then((res) => {

        if (res.code == 200) {
          console.log(res.total);
          tableData.data = res.data.records;
          if (res.data.pageSize != 0) {
            param.pageSize = res.data.pageSize;
            param.pageNum = res.data.pageNum;
            param.total = res.data.total;
            console.log(param.total);
          }


        }
      });
    };
    const addPlan = () => {
      router.push("/member/mySupplier/addSupplier");
    };
    const handleSizeChange = (val) => {
      param.pageSize = val;
     getSupplierList(param);
      console.log(`${val} items per page`);
    };
    const handleCurrentChange = (val) => {
      param.pageNum = val;
     getSupplierList(param);
      console.log(`current page: ${val}`);
    };
    onMounted(() => {
      if (route.query.orderState) {
        current_state.value = route.query.orderState;
      } else {
        current_state.value = "";
      }
      if (route.query.evaluateState) {
        evaluate_state.value = route.query.evaluateState;
      } else {
        evaluate_state.value = "";
      }
      //  getTime();
     getSupplierList(param);
      //  getCancelList();
      //  getAddressList();
    });

    router.beforeEach((to, from, next) => {
      //  if (to.query.orderState) {
      //    current_state.value = to.query.orderState;
      //  } else {
      //    current_state.value = "";
      //  }
      //  if (to.query.evaluateState) {
      //    evaluate_state.value = to.query.evaluateState;
      //  } else {
      //    evaluate_state.value = "";
      //  }
      if (to.path == "/member/procurement/procurementPlanList") {
        //getSupplierList(param);
      }
      next();
    });
    return {
      L,

      getSupplierList,
      onSubmit,
      param,
      tableData,
      toSupplierHomePage,
      addPlan,
      resetForm,
      ruleFormRef,
      //pageData,
      handleSizeChange,
      handleCurrentChange,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/orderList.scss";

.sld_order_list .container {
  width: 1018px;
}
.el-pager li.active {
  color: $colorMain;
  cursor: default;
  font-size: 16px;
}
.look {
  color: #0E3177FF;
  cursor: pointer;
}
.el-pager li:hover {
  color: $colorMain;
}

.el-select-dropdown__item.selected {
  color: $colorMain;
}

.sld_order_list {
  color: #000;
  .sld_List {
    color: #000;
  }
  /*::v-deep .el-dialog__header {
    background-color: $colorMain;
    padding: 11px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ::v-deep .el-dialog__title {
      color: #fff;
      font-size: 14px;
    }
    ::v-deep .el-dialog__headerbtn {
      top: auto;
    }
    ::v-deep .el-dialog__headerbtn .el-dialog__close {
      color: #fff !important;
      font-size: 18px;
    }
  }
   ::v-deep .el-dialog {
    overflow: hidden;
    border-radius: 4px;
  }
   ::v-deep .el-dialog__footer {
    display: flex;
    // justify-content: center;
    align-items: center;
  }*/
  .aft_btn:hover {
    background-color: rgba($color: #f30213, $alpha: 0.2);
    color: #f30213;
  }
  .aft_btn:focus {
    background-color: #fff;
    color: #333333;
    border-color: #dcdfe6;
  }
  .dia_btn {
    .el-button {
      width: 134px;
      height: 36px;
    }
  }
  .downLoad_contract {
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #e8e8f1;
    border-radius: 5px;
    cursor: pointer;
  }
  .downLoad_contract:hover {
    border: 1px solid $colorMain;
  }
}
.container {
  .bootom-footer {
    text-align: right;
  }
  .bootom-footer button:first-child {
    margin-right: 10px;
  }
  // .el-input {
  //   width: 300px;
  // }
  .unit {
    font-size: 18px;
    margin-left: 10px;
  }
}
.demo-form-inline .el-input {
  --el-input-width: 220px;
}
.el-table thead {
  color: #5f5f60;
}
.el-table {
  color: #2b2b2c;
}
.sld_order_button {
  margin-bottom: 10px;
}
.el-radio {
  margin-right: 0 !important;
}
.button {
  background: #0e3177;
  cursor: pointer;
  width: 70px;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  margin-left: 10px;
  line-height: 40px;
  font-size: 14px;
  margin-right: 10px;
}
.addButton {
  background: #0e3177;
  cursor: pointer;
  width: 100px;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  /*margin-left: 10px;*/
  line-height: 40px;
  font-size: 14px;
  margin-right: 10px;
}
.headerSearch {
  margin:0 auto;
  .inputSearch{
    width: 370px;
  }
  width:100%;
  position: relative;
}
.searchBtn {
  display: flex;
  width: 200px;
  position: absolute;
  top: 1%;
  left: 84%;
}
.greenCircle{
  width:5.8px;
  height:5.8px;
  background-color: rgb(56, 198, 56);
  border-radius: 50%;
  display:inline-block;
  margin-right:10px;
}
.redCircle{
  width:5.8px;
  height:5.8px;
  background-color:red;
  border-radius: 50%;
  display:inline-block;
  margin-right:10px;
}
</style>